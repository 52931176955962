$(document).on 'keyup', '.geocoder-wrapper .address_auto_complete_field', (e) ->
  input = $(this)
  wrapper = input.parents('.geocoder-wrapper:first')
  input.on('keypress', (e) ->
    if e.keyCode == 13
      e.preventDefault()
      e.stopPropagation()
  )

  placeAutocomplete = new google.maps.places.Autocomplete(input[0], types: [ 'geocode' ])
  placeAutocomplete.addListener('place_changed', () ->
    place = this.getPlace()
    $.each addressFieldsMapping, (key, mapping) ->
      wrapper.find(mapping.field).val('')
      wrapper.find('.address1').val('')
    if place.address_components.length > 1
      street_number = null
      route = null
      $.each place.address_components, (i, data) ->
        fieldMapping = addressFieldsMapping[data.types[0]]
        if fieldMapping?
          $.each wrapper.find(fieldMapping.field), (j, field) =>
            if $(field).val() == '' || $(field).val() == null
              $(field).val(data[fieldMapping.take]).trigger('change')
        else
          if data.types[0] == 'street_number'
            street_number = data.long_name
          if data.types[0] == 'route'
            route = data.long_name
      wrapper.find('input.address1').val([street_number, route].compact().join(' ')).trigger('change')
      wrapper.find('input.placeId').val(place.place_id)
      if place.geometry.location
        wrapper.find('input.lat').val(place.geometry.location.lat())
        wrapper.find('input.lng').val(place.geometry.location.lng())
      else
        wrapper.find('input.lat').val('')
        wrapper.find('input.lng').val('')
  )

addressFieldsMapping =
  administrative_area_level_2:
    field: 'input.district'
    take: 'short_name'
  locality:
    field: 'input.city'
    take: 'long_name'
  neighborhood:
    field: 'input.city'
    take: 'long_name'
  administrative_area_level_1:
    field: 'input.state'
    take: 'short_name'
  country:
    field: 'input.country'
    take: 'short_name'
  sublocality_level_1:
    field: 'input.region'
    take: 'long_name'
  postal_code:
    field: 'input.zip_code'
    take: 'short_name'

$(document).on 'click', '#address_edit', ->
  $('.company-addr-fields .address1').removeClass 'not-active'
  $('.company-addr-fields .city').removeClass 'not-active'
  $('.company-addr-fields .state').removeClass 'not-active'
  $('.company-addr-fields .zip_code').removeClass 'not-active'
  return

$(document).on 'click', '#company_legal_address_attributes_formatted_address', ->
  $('.company-addr-fields .address1').addClass 'not-active'
  $('.company-addr-fields .city').addClass 'not-active'
  $('.company-addr-fields .state').addClass 'not-active'
  $('.company-addr-fields .zip_code').addClass 'not-active'
  return

Object.defineProperty Array.prototype, 'compact', value: ->
  elem = undefined
  i = undefined
  len = undefined
  results = undefined
  results = []
  i = 0
  len = @length
  while i < len
    elem = @[i]
    if elem != null
      results.push elem
    i++
  results